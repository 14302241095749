import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';

import { passwordSchema, userNameSchema } from '~app/constants/zod-validations';

export const validator = withZod(
  z.object({
    userName: userNameSchema,
    password: passwordSchema,
  }),
);
