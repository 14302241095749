import { SignUpLoginContainer } from '~app/components/signup-login-container';

import Login from './login';

export { action } from './.server/action';
export { meta } from './meta';

export default function SignUpContainer() {
  return (
    <SignUpLoginContainer mode="login">
      <Login />
    </SignUpLoginContainer>
  );
}
