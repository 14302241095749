import {
  Box,
  Button,
  Flex,
  Link,
  Notification,
  Text,
  useToast,
} from '@iheartradio/web.companion';
import { isNotBlank } from '@iheartradio/web.utilities';
import {
  Link as RouterLink,
  useActionData,
  useNavigation,
  useSearchParams,
  useSubmit,
} from '@remix-run/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ValidatedForm } from 'remix-validated-form';

import { usePageView } from '~app/analytics/use-page-view';
import {
  PasswordField,
  SubmitButton,
  TextField,
} from '~app/components/input-fields/input-fields';
import { DoNotSellMyPersonalInformation } from '~app/components/privacy-terms-optout/do-not-sell';
import { SocialLogin } from '~app/components/social-login';
import { LOGIN as VALIDATION_ERRORS } from '~app/constants/validation-errors';
import { useConfig } from '~app/contexts/config';
import { useFingerprint, useSocialError } from '~app/hooks';

import type { LoginActionData } from './.server/action';
import { validator } from './utils';

type ToastMessage = { title: string; description?: string };

export const getToastMessage = (messageKey: string | null): ToastMessage => {
  if (messageKey === 'passwordUpdated') {
    return { title: 'Updated password', description: 'Please log in again' };
  }
  if (messageKey === 'loggedOut') {
    return { title: 'You have been logged out', description: '' };
  }
  return { title: '', description: '' };
};

// FIXME: This type is a workaround - need to refine the action a bit to get rid of this
type ActionData =
  | Exclude<LoginActionData, { fieldErrors: unknown }>
  | undefined;

export default function Login() {
  const transition = useNavigation();
  const actionData: ActionData = useActionData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showError, setShowError] = useState<boolean>(false);
  const { fpHash } = useFingerprint();
  const { SocialLoginErrorNotification, setProvider, setShowNotification } =
    useSocialError();
  const loginToken = searchParams.get('loginToken');
  const messageKey = searchParams.get('message');
  const redirectUrl = searchParams.get('redirectUrl');
  const submit = useSubmit();
  const toast = useToast();
  const isSubmitting = transition.state === 'submitting';
  const config = useConfig();

  // This section posts a message to an iframe hosted within the listen account that, once received
  // will set a localStorage value with the theme and authentication data passed. Once that localStorage event fires, a
  // listener will detect that event and:
  // 1. Set the chosen theme in the ThemeProvider
  // 2. Reset the client styles to apply the new theme
  // 3. Refresh the page to accurately reflect authentication status
  const bridgeRef = useRef<HTMLIFrameElement>(null);

  const { errors = {} } = actionData ?? {};

  usePageView('login');

  useEffect(() => {
    setShowError(Object.keys(errors).length > 0);
  }, [errors]);

  useEffect(() => {
    if (isNotBlank(messageKey)) {
      const message = getToastMessage(messageKey);

      if (isNotBlank(message)) {
        toast(dismiss => (
          <Notification
            data-test="success-notification"
            kind="success"
            onDismiss={dismiss}
            title={message.title}
          >
            {message.description}
          </Notification>
        ));
      }
    }
  }, [messageKey, searchParams, setSearchParams]);

  useEffect(() => {
    if (loginToken && fpHash) {
      const formData = new FormData();
      formData.append('loginToken', loginToken);
      formData.append('fpHash', fpHash);
      submit(formData, { method: 'post' });
    }
  }, [fpHash, loginToken, submit]);

  // hide social error notification on submit
  useEffect(() => {
    if (isSubmitting) {
      setShowNotification(false);
    }
  }, [isSubmitting, setShowNotification]);

  const sendLoginMessage = useCallback(() => {
    if (config.urls.listen) {
      bridgeRef.current?.contentWindow?.postMessage(
        {
          login: true,
        },
        // this tells `postMessage' that we intended to perform cross-origin communication
        // without this second argument the message will not get posted
        config.urls.listen,
      );
    }
  }, [config.urls.listen, bridgeRef]);

  return (
    <ValidatedForm id="login-form" method="POST" validator={validator}>
      <Flex
        data-test="login-form"
        direction="column"
        gap="$32"
        padding="0 $24 0 $24"
      >
        <SocialLoginErrorNotification />
        {!!errors.other?.length && showError ? (
          <Notification
            data-test="error-notification"
            kind="error"
            onDismiss={() => setShowError(false)}
            title={errors.other[0]}
          />
        ) : null}
        {(errors?.userName === VALIDATION_ERRORS.EMAIL_ERROR ||
          errors?.password === VALIDATION_ERRORS.PASSWORD_NOT_VALID) &&
        showError ? (
          <Notification
            data-test="wrong-credentials-notification"
            footer={
              <Button
                as={RouterLink}
                color={{ dark: 'white', light: 'gray' }}
                css={{ textDecoration: 'none' }}
                kind="primary"
                size="small"
                to="/forgot-password"
              >
                Forgot Password
              </Button>
            }
            kind="error"
            onDismiss={() => setShowError(false)}
            title="Wrong email or password"
          >
            Please try again with a different email address or password or try
            logging in another way
          </Notification>
        ) : null}
        {errors?.attempts && errors?.attempts.errorMessage ? (
          <Notification
            footer={
              <Button
                as={RouterLink}
                color={{ dark: 'white', light: 'gray' }}
                css={{ textDecoration: 'none' }}
                data-test="forgot-password-error-notification"
                kind="primary"
                size="small"
                to="/forgot-password"
              >
                Forgot Password
              </Button>
            }
            kind="error"
            onDismiss={() => setShowError(false)}
            title={errors?.attempts?.title}
          >
            {errors?.attempts?.errorMessage}
          </Notification>
        ) : null}
        {errors?.userName === VALIDATION_ERRORS.EMAIL_OAUTH && showError ? (
          <Notification
            data-test="oauth-error-notification"
            kind="neutral"
            onDismiss={() => setShowError(false)}
            title={errors?.userName ?? ''}
          >
            Please try logging in with {errors?.oauthProvider}
          </Notification>
        ) : null}
        {loginToken ? (
          <Notification
            data-test="logging-you-in-notification"
            kind="info"
            title="Logging you in..."
          >
            Please wait while we log you in
          </Notification>
        ) : null}
        {errors?.token ? (
          <Notification
            footer={
              <Button
                as={RouterLink}
                color={{ dark: 'white', light: 'gray' }}
                css={{ textDecoration: 'none' }}
                data-test="generate-login-link-button"
                kind="primary"
                size="small"
                to="/forgot-password"
              >
                Generate Login Link
              </Button>
            }
            kind="warning"
            title={errors?.token}
          >
            Please check the link, or generate a new one
          </Notification>
        ) : null}
        <TextField
          clearable={true}
          label="Email"
          name="userName"
          placeholder="Enter email address"
          type="text"
        />
        <PasswordField
          label="Password"
          name="password"
          placeholder="Enter password"
        />
        <Box>
          <Text kind="caption-3">
            <Link
              as={RouterLink}
              color="primary"
              to="/forgot-password"
              underline="hover"
            >
              Forgot your password?
            </Link>
          </Text>
        </Box>
        {redirectUrl ? (
          <input name="redirectUrl" type="hidden" value={redirectUrl} />
        ) : null}
        <input name="fpHash" type="hidden" value={fpHash} />
        <SubmitButton label="Log in" onClick={sendLoginMessage} />
      </Flex>
      <SocialLogin
        sendLoginMessage={sendLoginMessage}
        setProvider={setProvider}
        setShowNotification={setShowNotification}
      />
      <Box paddingLeft="$24">
        <Text kind="caption-4">
          <DoNotSellMyPersonalInformation color="primary" />
        </Text>
      </Box>
      <iframe
        frameBorder="0"
        height="0"
        ref={bridgeRef}
        src={`${config.urls.listen}/api/v1/bridge`}
        title="web.listen.bridge"
        width="0"
      />
    </ValidatedForm>
  );
}
